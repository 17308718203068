@charset "UTF-8";
/**
 * Mixin to create a keyframes for a notification
 * @param $direction (left|top|right|bottom) Translation direction
 * @param $margin-bottom Initial `margin-bottom` value
 */
/*
 * Mixin to create an animation for a notification
 * @param $direction (left|top|right|bottom) Translation direction
 * @param $margin-bottom Initial `margin-bottom` value
 */
@-webkit-keyframes styles_top20px-enter__1DaXj {
  0% {
    top: -360px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }
@keyframes styles_top20px-enter__1DaXj {
  0% {
    top: -360px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@-webkit-keyframes styles_top20px-leave__1WvTZ {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes styles_top20px-leave__1WvTZ {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@-webkit-keyframes styles_bottom20px-enter__367QF {
  0% {
    bottom: -360px;
    opacity: 0; }
  100% {
    bottom: 0;
    opacity: 1; } }

@keyframes styles_bottom20px-enter__367QF {
  0% {
    bottom: -360px;
    opacity: 0; }
  100% {
    bottom: 0;
    opacity: 1; } }

@-webkit-keyframes styles_bottom20px-leave__1Gjc9 {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes styles_bottom20px-leave__1Gjc9 {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@-webkit-keyframes styles_left20px-enter__1M3tQ {
  0% {
    left: -360px;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@keyframes styles_left20px-enter__1M3tQ {
  0% {
    left: -360px;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@-webkit-keyframes styles_left20px-leave__3iVtA {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes styles_left20px-leave__3iVtA {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@-webkit-keyframes styles_right20px-enter__3O-sW {
  0% {
    right: -360px;
    opacity: 0; }
  100% {
    right: 0;
    opacity: 1; } }

@keyframes styles_right20px-enter__3O-sW {
  0% {
    right: -360px;
    opacity: 0; }
  100% {
    right: 0;
    opacity: 1; } }

@-webkit-keyframes styles_right20px-leave__2G-PE {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes styles_right20px-leave__2G-PE {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 20px; }
  40% {
    margin-bottom: 20px;
    max-height: 200px; }
  50% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@-webkit-keyframes styles_top0-enter__St7Hn {
  0% {
    top: -360px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes styles_top0-enter__St7Hn {
  0% {
    top: -360px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@-webkit-keyframes styles_top0-leave__2EJ4v {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 0; }
  40% {
    margin-bottom: 0;
    max-height: 200px; }
  50% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@keyframes styles_top0-leave__2EJ4v {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    max-height: 200px;
    margin-bottom: 0; }
  40% {
    margin-bottom: 0;
    max-height: 200px; }
  50% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0; } }

@-webkit-keyframes styles_rotating__2R_XP {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg); }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg); } }

@keyframes styles_rotating__2R_XP {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg); }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg); } }

.styles_notifications-system__2ifY- {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429; }

.styles_notifications-container__34gz9 {
  position: fixed;
  z-index: 99999; }

.styles_notifications-container--tc__iOABH, .styles_notifications-container--tr__Nle4D, .styles_notifications-container--tl__GbhVX, .styles_notifications-container--bc__B4eaL, .styles_notifications-container--br__3XJsq, .styles_notifications-container--bl__2GoWq {
  width: 360px; }
  .styles_notifications-container--tc__iOABH .styles_notification--buttons-1__26cqf .styles_notification-button__3H__c, .styles_notifications-container--tr__Nle4D .styles_notification--buttons-1__26cqf .styles_notification-button__3H__c, .styles_notifications-container--tl__GbhVX .styles_notification--buttons-1__26cqf .styles_notification-button__3H__c, .styles_notifications-container--bc__B4eaL .styles_notification--buttons-1__26cqf .styles_notification-button__3H__c, .styles_notifications-container--br__3XJsq .styles_notification--buttons-1__26cqf .styles_notification-button__3H__c, .styles_notifications-container--bl__2GoWq .styles_notification--buttons-1__26cqf .styles_notification-button__3H__c {
    height: 100%; }
  .styles_notifications-container--tc__iOABH .styles_notification--buttons-2__1YZWk .styles_notification-button__3H__c, .styles_notifications-container--tr__Nle4D .styles_notification--buttons-2__1YZWk .styles_notification-button__3H__c, .styles_notifications-container--tl__GbhVX .styles_notification--buttons-2__1YZWk .styles_notification-button__3H__c, .styles_notifications-container--bc__B4eaL .styles_notification--buttons-2__1YZWk .styles_notification-button__3H__c, .styles_notifications-container--br__3XJsq .styles_notification--buttons-2__1YZWk .styles_notification-button__3H__c, .styles_notifications-container--bl__2GoWq .styles_notification--buttons-2__1YZWk .styles_notification-button__3H__c {
    height: 50%; }
  .styles_notifications-container--tc__iOABH .styles_notification-buttons__3vKL7, .styles_notifications-container--tr__Nle4D .styles_notification-buttons__3vKL7, .styles_notifications-container--tl__GbhVX .styles_notification-buttons__3vKL7, .styles_notifications-container--bc__B4eaL .styles_notification-buttons__3vKL7, .styles_notifications-container--br__3XJsq .styles_notification-buttons__3vKL7, .styles_notifications-container--bl__2GoWq .styles_notification-buttons__3vKL7 {
    flex-direction: column; }
    .styles_notifications-container--tc__iOABH .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c, .styles_notifications-container--tr__Nle4D .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c, .styles_notifications-container--tl__GbhVX .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c, .styles_notifications-container--bc__B4eaL .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c, .styles_notifications-container--br__3XJsq .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c, .styles_notifications-container--bl__2GoWq .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c {
      display: block;
      width: 100%; }
      .styles_notifications-container--tc__iOABH .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c:nth-child(2), .styles_notifications-container--tr__Nle4D .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c:nth-child(2), .styles_notifications-container--tl__GbhVX .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c:nth-child(2), .styles_notifications-container--bc__B4eaL .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c:nth-child(2), .styles_notifications-container--br__3XJsq .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c:nth-child(2), .styles_notifications-container--bl__2GoWq .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c:nth-child(2) {
        border-top: 1px solid rgba(0, 0, 0, 0.09); }

.styles_notifications-container--b__2grdm .styles_notification-buttons__3vKL7 .styles_notification-buttons__3vKL7, .styles_notifications-container--b__2grdm .styles_notification--buttons-1__26cqf .styles_notification-buttons__3vKL7, .styles_notifications-container--t__IfnRd .styles_notification-buttons__3vKL7 .styles_notification-buttons__3vKL7, .styles_notifications-container--t__IfnRd .styles_notification--buttons-1__26cqf .styles_notification-buttons__3vKL7 {
  flex-direction: row; }

.styles_notifications-container--t__IfnRd {
  width: calc(100% - 40px);
  top: 20px;
  left: 20px; }
  .styles_notifications-container--t__IfnRd .styles_notification-wrapper__3D-Vz {
    position: relative;
    top: 0; }
    .styles_notifications-container--t__IfnRd .styles_notification-wrapper-enter__1DYOL {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_top20px-enter__1DaXj;
      animation-name: styles_top20px-enter__1DaXj;
      -webkit-animation-duration: .4s;
      animation-duration: .4s; }
    .styles_notifications-container--t__IfnRd .styles_notification-wrapper-leave__1UCxK {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_top20px-leave__1WvTZ;
      animation-name: styles_top20px-leave__1WvTZ;
      -webkit-animation-duration: .8s;
      animation-duration: .8s; }

.styles_notifications-container--tc__iOABH {
  top: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .styles_notifications-container--tc__iOABH .styles_notification-wrapper__3D-Vz {
    position: relative;
    top: 0; }
    .styles_notifications-container--tc__iOABH .styles_notification-wrapper-enter__1DYOL {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_top20px-enter__1DaXj;
      animation-name: styles_top20px-enter__1DaXj;
      -webkit-animation-duration: .4s;
      animation-duration: .4s; }
    .styles_notifications-container--tc__iOABH .styles_notification-wrapper-leave__1UCxK {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_top20px-leave__1WvTZ;
      animation-name: styles_top20px-leave__1WvTZ;
      -webkit-animation-duration: .8s;
      animation-duration: .8s; }

.styles_notifications-container--tr__Nle4D {
  top: 20px;
  right: 20px; }
  .styles_notifications-container--tr__Nle4D .styles_notification-wrapper__3D-Vz {
    position: relative;
    right: 0; }
    .styles_notifications-container--tr__Nle4D .styles_notification-wrapper-enter__1DYOL {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_right20px-enter__3O-sW;
      animation-name: styles_right20px-enter__3O-sW;
      -webkit-animation-duration: .4s;
      animation-duration: .4s; }
    .styles_notifications-container--tr__Nle4D .styles_notification-wrapper-leave__1UCxK {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_right20px-leave__2G-PE;
      animation-name: styles_right20px-leave__2G-PE;
      -webkit-animation-duration: .8s;
      animation-duration: .8s; }

.styles_notifications-container--tl__GbhVX {
  top: 20px;
  left: 20px; }
  .styles_notifications-container--tl__GbhVX .styles_notification-wrapper__3D-Vz {
    position: relative;
    left: 0; }
    .styles_notifications-container--tl__GbhVX .styles_notification-wrapper-enter__1DYOL {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_left20px-enter__1M3tQ;
      animation-name: styles_left20px-enter__1M3tQ;
      -webkit-animation-duration: .4s;
      animation-duration: .4s; }
    .styles_notifications-container--tl__GbhVX .styles_notification-wrapper-leave__1UCxK {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_left20px-leave__3iVtA;
      animation-name: styles_left20px-leave__3iVtA;
      -webkit-animation-duration: .8s;
      animation-duration: .8s; }

.styles_notifications-container--b__2grdm {
  width: calc(100% - 40px);
  bottom: 0;
  left: 20px; }
  .styles_notifications-container--b__2grdm .styles_notification-wrapper__3D-Vz {
    position: relative;
    bottom: 0; }
    .styles_notifications-container--b__2grdm .styles_notification-wrapper-enter__1DYOL {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_bottom20px-enter__367QF;
      animation-name: styles_bottom20px-enter__367QF;
      -webkit-animation-duration: .4s;
      animation-duration: .4s; }
    .styles_notifications-container--b__2grdm .styles_notification-wrapper-leave__1UCxK {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_bottom20px-leave__1Gjc9;
      animation-name: styles_bottom20px-leave__1Gjc9;
      -webkit-animation-duration: .8s;
      animation-duration: .8s; }

.styles_notifications-container--bc__B4eaL {
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .styles_notifications-container--bc__B4eaL .styles_notification-wrapper__3D-Vz {
    position: relative;
    bottom: 0; }
    .styles_notifications-container--bc__B4eaL .styles_notification-wrapper-enter__1DYOL {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_bottom20px-enter__367QF;
      animation-name: styles_bottom20px-enter__367QF;
      -webkit-animation-duration: .4s;
      animation-duration: .4s; }
    .styles_notifications-container--bc__B4eaL .styles_notification-wrapper-leave__1UCxK {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_bottom20px-leave__1Gjc9;
      animation-name: styles_bottom20px-leave__1Gjc9;
      -webkit-animation-duration: .8s;
      animation-duration: .8s; }

.styles_notifications-container--br__3XJsq {
  bottom: 0;
  right: 20px; }
  .styles_notifications-container--br__3XJsq .styles_notification-wrapper__3D-Vz {
    position: relative;
    right: 0; }
    .styles_notifications-container--br__3XJsq .styles_notification-wrapper-enter__1DYOL {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_right20px-enter__3O-sW;
      animation-name: styles_right20px-enter__3O-sW;
      -webkit-animation-duration: .4s;
      animation-duration: .4s; }
    .styles_notifications-container--br__3XJsq .styles_notification-wrapper-leave__1UCxK {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_right20px-leave__2G-PE;
      animation-name: styles_right20px-leave__2G-PE;
      -webkit-animation-duration: .8s;
      animation-duration: .8s; }

.styles_notifications-container--bl__2GoWq {
  bottom: 0;
  left: 20px; }
  .styles_notifications-container--bl__2GoWq .styles_notification-wrapper__3D-Vz {
    position: relative;
    left: 0; }
    .styles_notifications-container--bl__2GoWq .styles_notification-wrapper-enter__1DYOL {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_left20px-enter__1M3tQ;
      animation-name: styles_left20px-enter__1M3tQ;
      -webkit-animation-duration: .4s;
      animation-duration: .4s; }
    .styles_notifications-container--bl__2GoWq .styles_notification-wrapper-leave__1UCxK {
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-name: styles_left20px-leave__3iVtA;
      animation-name: styles_left20px-leave__3iVtA;
      -webkit-animation-duration: .8s;
      animation-duration: .8s; }

@media (max-width: 767px) {
  .styles_notifications-container--t__IfnRd {
    width: 100%;
    top: 0;
    left: 0; }
    .styles_notifications-container--t__IfnRd .styles_notification-wrapper__3D-Vz {
      position: relative;
      top: 0; }
      .styles_notifications-container--t__IfnRd .styles_notification-wrapper-enter__1DYOL {
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-name: styles_top0-enter__St7Hn;
        animation-name: styles_top0-enter__St7Hn;
        -webkit-animation-duration: .4s;
        animation-duration: .4s; }
      .styles_notifications-container--t__IfnRd .styles_notification-wrapper-leave__1UCxK {
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-name: styles_top0-leave__2EJ4v;
        animation-name: styles_top0-leave__2EJ4v;
        -webkit-animation-duration: .8s;
        animation-duration: .8s; }
    .styles_notifications-container--t__IfnRd .styles_notification-wrapper__3D-Vz {
      margin: 0; } }

.styles_notification__1gs9V {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 40px;
  border: none;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.3);
  z-index: 999; }
  .styles_notification-wrapper__3D-Vz {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 20px; }
  .styles_notification-image-container__3O_dY {
    box-sizing: border-box;
    padding: 10px 0 10px 15px; }
  .styles_notification-image__1ERBg {
    display: inline-block;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    background-size: cover; }
  .styles_notification-icon__38SS4 {
    box-sizing: border-box;
    margin: 10px 0 10px 15px;
    font-size: 20px !important;
    align-self: flex-start; }
  .styles_notification-meta__2f9EC {
    vertical-align: top;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px; }
  .styles_notification-close-button-container__3eGMQ {
    padding: 10px 15px;
    box-sizing: border-box; }
  .styles_notification-close-button__YuxL8 {
    font-size: 14px !important;
    color: #524c4c;
    cursor: pointer; }
    .styles_notification-close-button__YuxL8:before {
      content: "\F00D"; }
    .styles_notification-close-button__YuxL8:hover {
      color: #6c6565; }
  .styles_notification-buttons__3vKL7 {
    display: flex;
    box-sizing: border-box;
    vertical-align: top; }
    .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c {
      min-height: 40px;
      box-sizing: border-box;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.09);
      outline: none;
      text-align: center;
      color: #524c4c;
      cursor: pointer; }
      .styles_notification-buttons__3vKL7 .styles_notification-button-text__1HF3O {
        display: block;
        height: 25px;
        padding: 0 15px;
        min-width: 90px;
        max-width: 150px;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        margin: 0 auto;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 14px;
        line-height: 25px; }
      .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c:hover {
        color: #349ef3; }
      .styles_notification-buttons__3vKL7 .styles_notification-button__3H__c:active {
        color: #0e86e6; }
  .styles_notification-title__3Qh5k {
    margin: 0 0 10px;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.428571429; }
  .styles_notification-message__3apgw {
    margin: 0; }
  .styles_notification--dismissible__388D3 {
    cursor: pointer; }
  .styles_notification--default__18rfU {
    background-color: #ffffff;
    border-left: 2px solid #ffffff;
    color: #524c4c; }
    .styles_notification--default__18rfU .styles_notification-icon__38SS4 {
      display: none; }
  .styles_notification--success__2U0nW {
    background-color: #ffffff;
    border-left: 2px solid #4dc657;
    color: #524c4c; }
    .styles_notification--success__2U0nW .styles_notification-icon__38SS4:before {
      content: "\F058";
      color: #4dc657; }
  .styles_notification--info__26_ge {
    background-color: #ffffff;
    border-left: 2px solid #349ef3;
    color: #524c4c; }
    .styles_notification--info__26_ge .styles_notification-icon__38SS4:before {
      content: "\F05A";
      color: #349ef3; }
  .styles_notification--warning__1NGVc {
    background-color: #ffffff;
    border-left: 2px solid #f5aa0a;
    color: #524c4c; }
    .styles_notification--warning__1NGVc .styles_notification-icon__38SS4:before {
      content: "\F071";
      color: #f5aa0a; }
  .styles_notification--error__2UKE0 {
    background-color: #ffffff;
    border-left: 2px solid #f5311d;
    color: #524c4c; }
    .styles_notification--error__2UKE0 .styles_notification-icon__38SS4:before {
      content: "\F057";
      color: #f5311d; }
  .styles_notification--loading__bjjHE {
    background-color: #ffffff;
    border-left: 2px solid #349ef3;
    color: #524c4c; }
    .styles_notification--loading__bjjHE .styles_notification-icon__38SS4 {
      -webkit-animation: styles_rotating__2R_XP 2s infinite linear;
      animation: styles_rotating__2R_XP 2s infinite linear; }
    .styles_notification--loading__bjjHE .styles_notification-icon__38SS4:before {
      content: "\F110";
      color: #349ef3; }

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  transition: all 0.3s ease-out; }

.introjs-showElement {
  z-index: 9999999 !important; }

tr.introjs-showElement > td {
  z-index: 9999999 !important;
  position: relative; }

tr.introjs-showElement > th {
  z-index: 9999999 !important;
  position: relative; }

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: #ffffff;
  opacity: 0;
  filter: alpha(opacity=0); }

.introjs-relativePosition {
  position: relative; }

.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  transition: all 0.3s ease-out; }
  .introjs-helperLayer * {
    box-sizing: content-box; }
    .introjs-helperLayer *:before {
      box-sizing: content-box; }
    .introjs-helperLayer *:after {
      box-sizing: content-box; }

.introjs-tooltipReferenceLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  transition: all 0.3s ease-out; }
  .introjs-tooltipReferenceLayer * {
    font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif; }

.introjs-helperNumberLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }

.introjs-arrow {
  border: 5px solid transparent;
  content: "";
  position: absolute; }

.introjs-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: #ffffff; }

.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: #ffffff; }

.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #ffffff; }

.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: #ffffff; }

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: #ffffff; }

.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: #ffffff; }

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #ffffff; }

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #ffffff; }

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: #ffffff; }

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: #ffffff; }

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #ffffff;
  min-width: 250px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  transition: opacity 0.1s ease-out; }

.introjs-tooltiptext {
  padding: 20px; }

.introjs-tooltip-title {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  float: left;
  line-height: 32px; }

.introjs-tooltip-header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px; }
  .introjs-tooltip-header:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  text-align: right;
  white-space: nowrap; }
  .introjs-tooltipbuttons:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid #bdbdbd;
  text-decoration: none;
  text-shadow: 1px 1px 0 #ffffff;
  font-size: 14px;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  background-color: #f4f4f4;
  border-radius: 0.2em;
  zoom: 1;
  *display: inline; }
  .introjs-button:hover {
    outline: none;
    text-decoration: none;
    border-color: #9e9e9e;
    background-color: #e0e0e0;
    color: #212121; }
  .introjs-button:focus {
    outline: none;
    text-decoration: none;
    background-color: #eeeeee;
    box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.5);
    border: 1px solid #616161;
    color: #212121; }
  .introjs-button:active {
    outline: none;
    text-decoration: none;
    background-color: #e0e0e0;
    border-color: #9e9e9e;
    color: #212121; }
  .introjs-button::-moz-focus-inner {
    padding: 0;
    border: 0; }

.introjs-skipbutton {
  box-sizing: content-box;
  color: #616161;
  float: right;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  padding: 7px 10px; }
  .introjs-skipbutton:hover, .introjs-skipbutton:focus {
    color: #212121;
    outline: none;
    text-decoration: none; }

.introjs-prevbutton {
  float: left; }

.introjs-nextbutton {
  float: right; }

.introjs-disabled {
  color: #9e9e9e;
  border-color: #bdbdbd;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none; }
  .introjs-disabled:hover, .introjs-disabled:focus {
    color: #9e9e9e;
    border-color: #bdbdbd;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none; }

.introjs-hidden {
  display: none; }

.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }
  .introjs-bullets ul {
    box-sizing: content-box;
    clear: both;
    margin: 0 auto 0;
    padding: 0;
    display: inline-block; }
    .introjs-bullets ul li {
      box-sizing: content-box;
      list-style: none;
      float: left;
      margin: 0 2px; }
      .introjs-bullets ul li a {
        transition: width 0.1s ease-in;
        box-sizing: content-box;
        display: block;
        width: 6px;
        height: 6px;
        background: #ccc;
        border-radius: 10px;
        text-decoration: none;
        cursor: pointer; }
        .introjs-bullets ul li a:hover, .introjs-bullets ul li a:focus {
          width: 15px;
          background: #999;
          text-decoration: none;
          outline: none; }
      .introjs-bullets ul li a.active {
        width: 15px;
        background: #999; }

.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #e0e0e0; }

.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c; }

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%; }

.introjs-fixedTooltip {
  position: fixed; }

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer; }
  .introjs-hint:focus {
    border: 0;
    outline: 0; }
  .introjs-hint:hover > .introjs-hint-pulse {
    background-color: rgba(60, 60, 60, 0.57); }

.introjs-hidehint {
  display: none; }

.introjs-fixedhint {
  position: fixed; }

@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

@keyframes introjspulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out;
  -webkit-animation: introjspulse 2s infinite;
          animation: introjspulse 2s infinite; }

.introjs-hint-no-anim .introjs-hint-pulse {
  -webkit-animation: none;
          animation: none; }

.introjs-hint-dot {
  box-sizing: content-box;
  background: transparent;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: -18px;
  left: -18px;
  z-index: 1;
  opacity: 0; }

/* --- Basics --- */

.loading {
  display: inline-block;
  overflow: hidden;
  height: 1.3em;
  margin-top: -0.3em;
  line-height: 1.5em;
  vertical-align: text-bottom;
}

.loading::after {
  display: inline-table;
  white-space: pre;
  text-align: left;
}

/* --- Types --- */

/* default loading is ellip */
.loading::after {
  content: "\A.\A..\A...";
  -webkit-animation: spin4 2s steps(4) infinite;
          animation: spin4 2s steps(4) infinite;
}

.loading.line::after {
  content: "/\A\2013\A\\\A|";
  text-align: center;
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.line2::after {
  content: "\2572\A\2502\A\2571\A\2500";
  text-align: center;
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.plus::after {
  content: "\253D\A\2540\A\253E\A\2541";
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.dots::after {
  content: "\280B\A\2819\A\2839\A\2838\A\283C\A\2834\A\2826\A\2827\A\2807\A\280F";
  -webkit-animation: spin10 1s steps(10) infinite;
          animation: spin10 1s steps(10) infinite;
}

.loading.dots2::after {
  content: "\280B\A\2819\A\281A\A\281E\A\2816\A\2826\A\2834\A\2832\A\2833";
  -webkit-animation: spin9 1s steps(9) infinite;
          animation: spin9 1s steps(9) infinite;
}

.loading.dots3::after {
  content: "\22EE\A\22F0\A\22EF\A\22F1";
  text-align: center;
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.lifting::after {
  content: "\A708\A70D\A\A709\A70E\A\A70A\A70F\A\A70B\A710\A\A70C\A711";
  -webkit-animation: spin5 .5s steps(5) infinite alternate;
          animation: spin5 .5s steps(5) infinite alternate;
}

.loading.hamburger::after {
  content: "\2631\A\2632\A\2634";
  -webkit-animation: spin3 .3s steps(3) infinite alternate;
          animation: spin3 .3s steps(3) infinite alternate;
}

.loading.bar::after {
  content: "\258F\A\258E\A\258D\A\258C\A\258B\A\258A\A\2589";
  -webkit-animation: spin7 1s steps(7) infinite alternate;
          animation: spin7 1s steps(7) infinite alternate;
}

.loading.bar2::after {
  content: "\2581\A\2582\A\2583\A\2584\A\2585\A\2586\A\2587\A\2588";
  -webkit-animation: spin8 2s steps(8) infinite alternate;
          animation: spin8 2s steps(8) infinite alternate;
}

.loading.circle::after {
  content: "\25F4\A\25F7\A\25F6\A\25F5";
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.open-circle::after {
  content: "\25DC\A\25E0\A\25DD\A\25DE\A\25E1\A\25DF";
  -webkit-animation: spin6 .6s steps(6) infinite;
          animation: spin6 .6s steps(6) infinite;
}

.loading.arrow::after {
  content: "\2190\A\2196\A\2191\A\2197\A\2192\A\2198\A\2193\A\2199";
  -webkit-animation: spin8 1s steps(8) infinite;
          animation: spin8 1s steps(8) infinite;
}

.loading.triangle::after {
  content: "\25E2\A\25E3\A\25E4\A\25E5";
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.triangles::after {
  content: "\25B9\25B9\25B9\25B9\25B9\A   \25B8\25B9\25B9\25B9\25B9\A   \25B9\25B8\25B9\25B9\25B9\A   \25B9\25B9\25B8\25B9\25B9\A   \25B9\25B9\25B9\25B8\25B9\A   \25B9\25B9\25B9\25B9\25B8";
  -webkit-animation: spin6 1s steps(6) infinite;
          animation: spin6 1s steps(6) infinite;
}

.loading.beam::after {
  content: "\A=   \A   ==  \A   === \A   ====\A    ===\A     ==\A      =\A";
  -webkit-animation: spin9 1.2s steps(9) infinite;
          animation: spin9 1.2s steps(9) infinite;
  font-family: monospace;
}

.loading.bullet::after {
  content: " \25CF      \A     \25CF     \A      \25CF    \A       \25CF   \A        \25CF\A       \25CF   \A      \25CF    \A     \25CF     \A    \25CF      \A   \25CF       ";
  -webkit-animation: spin10 1s steps(10) infinite;
          animation: spin10 1s steps(10) infinite;
}

.loading.bullseye::after {
  content: "\25CE\25CE\25CE\A\25C9\25CE\25CE\A\25CE\25C9\25CE\A\25CE\25CE\25C9";
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.rhomb::after {
  content: "\25C7\25C7\25C7\A\25C8\25C7\25C7\A\25C7\25C8\25C7\A\25C7\25C7\25C8";
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.fish::after {
  content: ">))'>\A   \2003>))'>\A   \2003\2003>))'>\A   \2003\2003\2003>))'>\A   \2003\2003\2003\2003>))'>\A   \2003\2003\2003\2003<'((<\A   \2003\2003\2003<'((<\A   \2003\2003<'((<\A   \2003<'((<\A   <'((<\A";
  -webkit-animation: spin10 5s steps(10) infinite;
          animation: spin10 5s steps(10) infinite;
}

.loading.toggle::after {
  content: "\22B6\A\22B7";
  -webkit-animation: spin2 1s steps(2) infinite;
          animation: spin2 1s steps(2) infinite;
}

.loading.countdown::after {
  content: "0\A   1\A   2\A   3\A   4\A   5\A   6\A   7\A   8\A   9";
  animation: spin10 10s steps(10) reverse;
}

.loading.time::after {
  content: "\1F550\A\1F551\A\1F552\A\1F553\A\1F554\A\1F555\A\1F556\A\1F557\A\1F558\A\1F559\A\1F55A\A\1F55B";
  -webkit-animation: spin12 3s steps(12) infinite;
          animation: spin12 3s steps(12) infinite;
  width: 1.3em;
}

.loading.hearts::after {
  content: "\1F49B\A\1F499\A\1F49C\A\1F49A";
  -webkit-animation: spin4 2s steps(4) infinite;
          animation: spin4 2s steps(4) infinite;
  width: 1.3em;
}

.loading.earth::after {
  content: "\1F30D\A\1F30E\A\1F30F";
  -webkit-animation: spin3 1s steps(3) infinite;
          animation: spin3 1s steps(3) infinite;
  width: 1.3em;
}

.loading.moon::after {
  content: "\1F311\A\1F312\A\1F313\A\1F314\A\1F315\A\1F316\A\1F317\A\1F318";
  -webkit-animation: spin8 2s steps(8) infinite;
          animation: spin8 2s steps(8) infinite;
  width: 1.3em;
}

.loading.monkey::after {
  content: "\1F648\A\1F649\A\1F64A";
  -webkit-animation: spin3 1.5s steps(3) infinite;
          animation: spin3 1.5s steps(3) infinite;
  width: 1.3em;
}

.loading.runner::after {
  content: "\1F6B6\A\1F3C3";
  -webkit-animation: spin2 1s steps(2) infinite;
          animation: spin2 1s steps(2) infinite;
  width: 1.3em;
}

.loading.box-bounce::after {
  content:"\2596\A\2598\A\259D\A\2597";
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.star::after {
  content:"\2736\A\2738\A\2739\A\273A\A\2739\A\2737";
  -webkit-animation: spin6 1s steps(6) infinite;
          animation: spin6 1s steps(6) infinite;
}

.loading.matrix::after {
  content:"\2591   \2591\2591\2591\2591\A\2591\2591   \2591\2591\2591\A\2591\2591\2591   \2591\2591\A\2591\2591\2591\2591   \2591";
  -webkit-animation: spin4 .5s steps(4) infinite alternate;
          animation: spin4 .5s steps(4) infinite alternate;
}

.loading.square::after {
  content: "\25F0\A\25F3\A\25F2\A\25F1";
  -webkit-animation: spin4 1s steps(4) infinite;
          animation: spin4 1s steps(4) infinite;
}

.loading.words::after {
  content: "Loading\A   Still loading\A   Mostly done\A   A bit more \A   Almost done\A   Ready-ish";
  -webkit-animation: spin6 12s steps(6) infinite;
          animation: spin6 12s steps(6) infinite;
}

/* --- Animations --- */

@-webkit-keyframes spin1  { to { -webkit-transform: translateY( -1.5em); transform: translateY( -1.5em); } }

@keyframes spin1  { to { -webkit-transform: translateY( -1.5em); transform: translateY( -1.5em); } }
@-webkit-keyframes spin2  { to { -webkit-transform: translateY( -3.0em); transform: translateY( -3.0em); } }
@keyframes spin2  { to { -webkit-transform: translateY( -3.0em); transform: translateY( -3.0em); } }
@-webkit-keyframes spin3  { to { -webkit-transform: translateY( -4.5em); transform: translateY( -4.5em); } }
@keyframes spin3  { to { -webkit-transform: translateY( -4.5em); transform: translateY( -4.5em); } }
@-webkit-keyframes spin4  { to { -webkit-transform: translateY( -6.0em); transform: translateY( -6.0em); } }
@keyframes spin4  { to { -webkit-transform: translateY( -6.0em); transform: translateY( -6.0em); } }
@-webkit-keyframes spin5  { to { -webkit-transform: translateY( -7.5em); transform: translateY( -7.5em); } }
@keyframes spin5  { to { -webkit-transform: translateY( -7.5em); transform: translateY( -7.5em); } }
@-webkit-keyframes spin6  { to { -webkit-transform: translateY( -9.0em); transform: translateY( -9.0em); } }
@keyframes spin6  { to { -webkit-transform: translateY( -9.0em); transform: translateY( -9.0em); } }
@-webkit-keyframes spin7  { to { -webkit-transform: translateY(-10.5em); transform: translateY(-10.5em); } }
@keyframes spin7  { to { -webkit-transform: translateY(-10.5em); transform: translateY(-10.5em); } }
@-webkit-keyframes spin8  { to { -webkit-transform: translateY(-12.0em); transform: translateY(-12.0em); } }
@keyframes spin8  { to { -webkit-transform: translateY(-12.0em); transform: translateY(-12.0em); } }
@-webkit-keyframes spin9  { to { -webkit-transform: translateY(-13.5em); transform: translateY(-13.5em); } }
@keyframes spin9  { to { -webkit-transform: translateY(-13.5em); transform: translateY(-13.5em); } }
@-webkit-keyframes spin10 { to { -webkit-transform: translateY(-15.0em); transform: translateY(-15.0em); } }
@keyframes spin10 { to { -webkit-transform: translateY(-15.0em); transform: translateY(-15.0em); } }
@-webkit-keyframes spin11 { to { -webkit-transform: translateY(-16.5em); transform: translateY(-16.5em); } }
@keyframes spin11 { to { -webkit-transform: translateY(-16.5em); transform: translateY(-16.5em); } }
@-webkit-keyframes spin12 { to { -webkit-transform: translateY(-18.0em); transform: translateY(-18.0em); } }
@keyframes spin12 { to { -webkit-transform: translateY(-18.0em); transform: translateY(-18.0em); } }

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

